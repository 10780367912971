import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const ProductOpenRTOS = () => (
  <Layout>
    <Seo title="OPENRTOS" />
    <main>
      {/* TITLE */}
      <section>
        <div className="container px-5">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xxl-6">
              <div className="text-center my-5">
                <h1 className="fw-bolder mb-3">OPENRTOS</h1>
                <p className="lead fw-normal text-muted mb-4">
                  FreeRTOSの商用ライセンスバージョン
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* MAIN */}
      <section className="bg-dark">
        <div className="container">
          <div className="row gx-5 justify-content-center">
            <div className="col-lg-8">
              <div className="row py-lg-5 py-4">
                <img
                  className="img-fluid rounded-3"
                  src="/images/product_openrtos/openrtos_main.svg"
                  alt="stm32f750-discovery-1"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="row py-lg-5 py-4">
                <h1
                  className="py-lg-5 text-center text-lg-start"
                  style={{
                    color: "#fff",
                    textShadow: "0 0 20px rgba(255,255,255,0.5)",
                  }}
                >
                  商用利用OK
                  <br />
                  著作権表示不要
                  <br />
                  力強いサポート
                  <br />
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </Layout>
)

export default ProductOpenRTOS
